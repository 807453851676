.logoImage{
    width: 100px !important;
    height: 100px !important;
}
.mx-wid-900px{
    max-width: 900px ;
}

.slide-text-subtitle{
    font-size: 25px;
}

.height-with-60{
    height: 60px;
    width: 60px;
}
.mx-500{
    max-width: 500px;
}
.testimonial-margin{
    margin: 90px 0 135px 0;
}
.text-orange {

    color: orange !important;
}

.carousel-control-prev,
.carousel-control-next {
  background-color: #32483c !important;
  color: #2c6a48 !important;
  width: 30px;
  height: 30px; 
  border-radius: 50%; 
}

.carousel-control-prev .carousel-control-prev-icon{

    background-color: #32483c !important;
    color: #2c6a48 !important;
    width: 30px;
    height: 30px; 
    border-radius: 50%; 
}
.new-testimonail-margin-left{
    margin-left: -90px !important;
}
.new-testimonail-margin-right{
    margin-right: -90px !important;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.p-30px{
padding: 30px;

}
.w-75px{
    width: 75px;
}
.h-55px{
    height: 55px;
}

.wh-45px{
    width: 45px;
    height: 45px;
}


